import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('ideaCollection', [
      'getIdeasCount',
      'getSharedIdeasCount'
    ]),

    hasIdeasOrSharedIdeas () {
      return this.getIdeasCount || this.getSharedIdeasCount
    },

    firstTime () {
      return this.$route.query.m !== undefined
    },

    isTemplateFromSite () {
      return this.$route.query.template !== undefined
    }
  }
}
