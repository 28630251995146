import store from '@/store'

const allowedParams = ['plan', 'type', 'noi']
const allowedPlans = ['dreamer', 'founder', 'team', 'teamPro']
const allowedTypes = ['monthly', 'annual', 'lifetime']
const allowedNoIdeas = [1, 3, 10, 30]
const isAppSumoUser = store.getters['user/isAppSumoUser']

const planMapper = {
  monthly: {
    dreamer: 1,
    founder: 12,
    team: 14,
    teamPro: 34
  },
  annual: {
    dreamer: 6,
    founder: 17,
    team: 19,
    teamPro: 39
  },
  lifetime: {
    dreamer: 22,
    founder: 21,
    team: 23,
    teamPro: 44
  }
}

const lifetimePlanMapper = {
  dreamer: 22,
  founder: isAppSumoUser ? 24 : 21,
  team: isAppSumoUser ? 25 : 23,
  teamPro: 44
}

function paymentQueryHelper (isLifetime, queryParams) {
  if (!allowedParams.includes('plan') || !allowedParams.includes('type') || !allowedParams.includes('noi')) return null
  if (!allowedPlans.includes(queryParams.plan)) return null
  if (!allowedNoIdeas.includes(Number(queryParams.noi))) return null
  queryParams.noi = Number(queryParams.noi)
  if (!isLifetime) {
    if (!allowedTypes.includes(queryParams.type)) return null
  }

  return queryParams
}

export {
  planMapper,
  lifetimePlanMapper,
  paymentQueryHelper
}
